import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css'; // Import the CSS file

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const App = () => {
  const [eventDetails, setEventDetails] = useState(null);
  const [eventId, setEventId] = useState(null);
  const [inviteId, setInviteId] = useState(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    // Extract eventId and inviteId from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const eventIdFromUrl = urlParams.get('eventId');
    const inviteIdFromUrl = urlParams.get('inviteId');

    // Update state with the IDs from the URL
    setEventId(eventIdFromUrl);
    setInviteId(inviteIdFromUrl);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make API call to fetch event details using the eventId
        if (eventId) {
          const response = await axios.get(`https://dev-api.skroll.cloud/event/${eventId}`);
          setEventDetails(response.data);
        }
      } catch (error) {
        console.error('Error fetching event details:', error);
      }
    };

    fetchData();
  }, [eventId]);

  const handlePresence = async (presence) => {
    try {
      // Make API call to mark presence using the eventId and inviteId
      if (eventId && inviteId) {
        await axios.patch(`https://dev-api.skroll.cloud/event/${eventId}/invite/${inviteId}`, {
          status: presence.toLowerCase(), // Assuming presence is 'Yes', 'No', or 'Maybe'
        });
        alert(`Presence marked as ${presence}`);
      }
    } catch (error) {
      console.error('Error marking presence:', error);
    }
  };

  return (
    <div className="container">
      {eventDetails ? (
        <div className="event-details">
          <h2>{eventDetails.data.name}</h2>
          <p>{eventDetails.data.description}</p>
          <p>Start Time: {new Date(eventDetails.data.startTime).toLocaleString()}</p>
          <p>End Time: {new Date(eventDetails.data.endTime).toLocaleString()}</p>
          <p>Address: {eventDetails.data.address}</p>
          <Slider {...settings}>
            {eventDetails.data.documents.map((document, index) => (
              <div key={index}>
                <img className="carousel-image" src={document.url} alt={`Event Photo ${index}`} />
              </div>
            ))}
          </Slider>
        </div>
      ) : (
        <p>Loading event details...</p>
      )}
      <div className="attendance-message">
        <h2>Mark your attendance and let the organizer know about your invite by informing them that you are going?</h2>
      </div>
      <div className="presence-buttons">
        <button className="yes-button" onClick={() => handlePresence('Yes')}>Yes</button>
        <button className="no-button" onClick={() => handlePresence('No')}>No</button>
        <button className="maybe-button" onClick={() => handlePresence('Maybe')}>Maybe</button>
      </div>
    </div>
  );
};

export default App;
